export function initToggleNav() {
  const body = document.body
  const closeButton = body.querySelector("[data-menu-toggle='close']")
  const openButton = body.querySelector("[data-menu-toggle='open']")

  const openMenu = (target) => {
    target.setAttribute("aria-expanded", true)
    body.classList.remove("menu-is-hidden")
    body.classList.remove("menu-is-closed")
    body.classList.add("menu-is-open")
    closeButton.focus()
  }

  const closeMenu = (target) => {
    target.setAttribute("aria-expanded", false)
    body.classList.remove("menu-is-open")
    body.classList.add("menu-is-closed")
    setTimeout(() => {
      body.classList.add("menu-is-hidden")
    }, 300)
    openButton.focus()
  }

  body.addEventListener("click", (e) => {
    const target = e.target?.closest("[data-menu-toggle]")
    if (target) {
      e.preventDefault()
      if (body.classList.contains("menu-is-open")) {
        closeMenu(target)
      } else {
        openMenu(target)
      }
    }
  })
  body.addEventListener("keydown", (e) => {
    if (e.key === "Escape" && body.classList.contains("menu-is-open")) {
      closeMenu(body.querySelector(".mobile-menu"))
    }
  })
}

export function initSubmenu() {
  const wrap = document.querySelector(".menu-wrap")
  const submenuWrap = wrap.querySelector(".submenu-wrap")
  const navLinks = wrap.querySelectorAll(".submenu-link")

  let linkContainerWidth
  const containerObserver = new ResizeObserver((containers) => {
    for (const container of containers) {
      linkContainerWidth = container.contentRect?.width
    }
  })
  if (navLinks) {
    containerObserver.observe(submenuWrap)
  }

  for (const navLink of navLinks) {
    navLink.onclick = (e) => {
      e.preventDefault()
      const target = wrap.querySelector(`${navLink.dataset.target}`)
      const indent = Number.parseInt(wrap.dataset.indent)
      if (indent) {
        wrap.style = `transform: translateX(-${2 * linkContainerWidth}px);`
        wrap.dataset.indent = 2
      } else {
        wrap.style = `transform: translateX(-${linkContainerWidth}px);`
        wrap.dataset.indent = 1
      }
      target.classList.remove("invisible")
      target.classList.add("visible")
    }
  }

  const navBackLinks = document.querySelectorAll(".submenu-backlink")

  for (const navBackLink of navBackLinks) {
    navBackLink.onclick = (e) => {
      e.preventDefault()
      const indent = Number.parseInt(wrap.dataset.indent)
      if (indent === 2) {
        wrap.style = `transform: translateX(-${linkContainerWidth}px);`
        wrap.dataset.indent = 1
      } else {
        wrap.removeAttribute("data-indent")
        wrap.style = ""
      }
      const current = navBackLink.parentElement
      current.classList.remove("visible")
      current.classList.add("invisible")
    }
  }
}
